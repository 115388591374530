import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import './index.css';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContextProvider } from './contexts/GlobalContext';

window.platform = window.sessionStorage.getItem("platform") || "timeline";

ReactDOM.render(
  <HelmetProvider>
    <GlobalContextProvider>
      <SidebarProvider>
        <BrowserRouter>
          <App />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            transition={Slide}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
          />
        </BrowserRouter>
      </SidebarProvider>
    </GlobalContextProvider>
  </HelmetProvider>,
  document.getElementById('root')
);
