import { FC, useState, createContext } from 'react';

// GlobalContext
type DialogContext = {
  toggleList: boolean;
  setToggleList: Function;
  page:number,
  setPage:any,
  limit:number, 
  setLimit:any,
  sAdminData:any, 
  setSadminData:any,
  refreshEventList:any, 
  setRefreshEventList:any,
  AdminData:any, 
  setadminData:any,
  tabValue: any;
  setTabValue: any;
  setRefreshEraList:any,
  refreshEraList:boolean,
  refreshImageMediaList:any, 
  setRefreshImageMediaList:any,
  profilePic:any, 
  setProfilePic:any
  imgProfilePath:any, 
  setProfileImagePath:any
  eventThumbnail:any,
  setEventThumbnail:any,
  eventThumbnailPath:any, 
  setEventThumbnailPath:any,
  searchValue:any, 
  setsearchValue:any,
  imageLoader:any, 
  setImageLoader:any,
  value:string, 
  setValue:any,
  loading:boolean, 
  setLoading:any
  eventimageLoader:any, 
  setEventImageLoader:any,
  refreshUserList:any, 
  setRefreshUserList:any
};

interface ProfileInterface {
  imageUrl: string;
  baseUrl: string;
}
const initialProfile = {
  baseUrl: "",
  imageUrl: "",
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GlobalContext = createContext<DialogContext>({} as DialogContext);

export const GlobalContextProvider: FC = ({ children }) => {
  const [toggleList, setToggleList] = useState(true);
  //Page And limit for Era Event Table
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  //For Edit Superadmin Store data
  const [sAdminData, setSadminData] = useState<any>();
  const [AdminData, setadminData] = useState<any>();
  const [refreshEventList, setRefreshEventList] = useState(true);
  const [refreshEraList, setRefreshEraList] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [refreshImageMediaList, setRefreshImageMediaList] = useState(false);
  const [profilePic, setProfilePic] =   useState<ProfileInterface>(initialProfile);
  const [imgProfilePath, setProfileImagePath] = useState<any>(null)
  const [eventThumbnail, setEventThumbnail] =   useState<ProfileInterface>(initialProfile);
  const [eventThumbnailPath, setEventThumbnailPath] = useState<any>(null)
  const [searchValue, setsearchValue] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [value, setValue] = useState('1');
  const [loading, setLoading] = useState(false);
  const [eventimageLoader, setEventImageLoader] = useState(false);
  const [refreshUserList, setRefreshUserList] = useState(false);

  return (
    <GlobalContext.Provider value={{ 
      toggleList, 
      setToggleList ,
      page, 
      setPage,
      limit,
      setLimit,
      sAdminData, 
      setSadminData,
      refreshEventList, 
      setRefreshEventList,
      AdminData, 
      setadminData,
      tabValue, 
      setTabValue,
      refreshEraList, 
      setRefreshEraList,
      refreshImageMediaList, 
      setRefreshImageMediaList,
      profilePic, 
      setProfilePic,
      imgProfilePath,
      setProfileImagePath,
      eventThumbnail,
      setEventThumbnail,
      eventThumbnailPath, 
      setEventThumbnailPath,
      searchValue,
      setsearchValue,
      imageLoader, 
      setImageLoader,
      value, 
      setValue,
      loading, 
      setLoading,
      eventimageLoader, 
      setEventImageLoader,
      refreshUserList, 
      setRefreshUserList

      }}>
      {children}
    </GlobalContext.Provider>
  );
};
